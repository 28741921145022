<template>
  <App
    left-arrow
    title="试驾跟进"
    @click-right="onClickRight"
    class="allSearch"
  >
    <Tabs
      v-model="tabActive"
      slot="extra"
      @change="tableChange"
    >
      <Tab
        :title="$i.title"
        v-for="$i in tabsData"
        :key="$i.label"
        :name="$i.label"
      >
      </Tab>
    </Tabs>
    <PullRefresh
      @refresh="onRefresh"
      v-model="loading"
    >
      <List
        v-model="loading"
        :immediate-check="false"
        :error.sync="error"
        :finished="finished"
        :finished-text="total === 0 ? 'No data!' : 'No more items.!'"
        error-text="Loading failed. Click to retry"
        @load="Onload"
      >
        <CardItem
          v-for="$items in list"
          :key="$items.id"
          :cardInfo="$items"
          :type="tabActive"
        />
      </List>
    </PullRefresh>
  </App>
</template>

<script>
import { Tab, Tabs, PullRefresh, List, Toast, Dialog } from 'vant'
import dayjs from 'dayjs'
import { debounce } from 'lodash-es'
import { tabsData } from './attr.js'
import CardItem from './components/card/index'
import { testdrtveTodayList, pdcList, pdcFinish, noShowList, tdFollowUpList } from '@/api/follow/index'
import '@/style/vant-patch.less'
export default {
  name: 'testDriveFollowUp',
  data () {
    return {
      showResult: false,
      hasDate: true,
      loading: false,
      finished: false,
      error: false,
      list: [],
      tabActive: this.$route.query.tabName,
      tabsData: [],
      page: 1,
      size: 10,
      total: 0,
      status: [],
      oppStatus: []
    }
  },
  components: {
    Tab,
    Tabs,
    CardItem,
    List,
    PullRefresh,
  },
  methods: {
    getData: debounce(function () {
      if (this.finished) return
      switch (this.tabActive) {
        case 'followUp':
          this.getTestdrtveTodayList()
          break;
        case 'PDC':
          this.getPdcList()
          break;
        case 'TDNoShow':
          this.getNoShowList()
          break;
        case 'TDFollowUp':
          this.getTdFollowUpList()
          break;
      }
    }, 600, { leading: true, trailing: false }),
    getStatus () {
      this.status = this.$storage.get('baseData')['testdrive_status']
      this.oppStatus = this.$storage.get('baseData')['opp_status']
    },
    statusChange (data) {
      data.forEach(el => {
        if (this.tabActive !== 'TDFollowUp') {
          this.status.forEach(item => {
            if (el.testDriveStatus === item.value) {
              el.testDriveStatus = item.key
            }
          })
        } else {
          this.oppStatus.forEach(item => {
            if (el.status === item.value) {
              el['testDriveStatus'] = item.key
            }
          })
        }

        el['qbsFollowupTime'] = dayjs(el['qbsFollowupTime']).format('YYYY/MM/DD')
      })
    },
    // 今日待跟进
    async getTestdrtveTodayList () {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const res = await testdrtveTodayList({ 'page': this.page, 'size': this.size, 'currentIdentity': this.$route.query.currentIdentity })
      Toast.clear()
      if (!res) return
      if (res.success) {
        this.statusChange(res.data.records)
        this.total = res.data.total
        this.list = [...this.list, ...res.data.records]
        if (this.list.length === res.data.total) {
          this.finished = true
        } else {
          this.page++
        }
        this.error = false
        this.loading = false
      } else {
        this.error = true
        this.loading = false
        Toast(res.msg)
      }
    },
    // pdc
    async getPdcList () {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const res = await pdcList({ 'page': this.page, 'size': this.size, 'currentIdentity': this.$route.query.currentIdentity })
      Toast.clear()
      if (!res) return
      if (res.success) {
        this.statusChange(res.data.records)
        this.total = res.data.total
        this.list = [...this.list, ...res.data.records]
        if (this.list.length === res.data.total) {
          this.finished = true
        } else {
          this.page++
        }
        this.error = false
        this.loading = false
      } else {
        this.error = true
        this.loading = false
        Toast(res.msg)
      }
    },
    // 完成任务
    finishTestdrive (id) {
      Dialog.confirm({
        title: '确认',
        message: '确认完成任务？',
      })
        .then(async () => {
          /* Toast.loading({
            message: 'Loading...',
            forbidClick: true,
            loadingType: 'spinner',
            overlay: true
          }) */
          const res = await pdcFinish(id)
          Toast.clear()
          if (!res) return
          if (res.success) {
            this.page = 1
            this.list = []
            this.getPdcList()
          } else {

            Toast(res.msg)
          }
        })
        .catch(() => {
          // on cancel
        });

    },
    // no-show
    async getNoShowList () {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const res = await noShowList({ 'page': this.page, 'size': this.size, 'currentIdentity': this.$route.query.currentIdentity })
      Toast.clear()
      if (!res) return
      if (res.success) {
        this.statusChange(res.data.records)
        this.total = res.data.total
        this.list = [...this.list, ...res.data.records]
        if (this.list.length === res.data.total) {
          this.finished = true
        } else {
          this.page++
        }
        this.error = false
        this.loading = false
      } else {
        this.error = true
        this.loading = false
        Toast(res.msg)
      }
    },
    // follow-up
    async getTdFollowUpList () {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const res = await tdFollowUpList({ 'page': this.page, 'size': this.size, 'currentIdentity': this.$route.query.currentIdentity })
      Toast.clear()
      if (!res) return
      if (res.success) {
        this.statusChange(res.data.records)
        this.total = res.data.total
        this.list = [...this.list, ...res.data.records]
        if (this.list.length === res.data.total) {
          this.finished = true
        } else {
          this.page++
        }
        this.error = false
        this.loading = false
      } else {
        this.error = true
        this.loading = false
        Toast(res.msg)
      }
    },
    tableChange () {
      this.page = 1
      this.list = []
      this.total = 0
      this.finished = false
      this.loading = false
      this.getData()
    },
    // 下拉刷新
    onRefresh () {
      this.page = 1
      this.finished = false
      this.list = []
      this.total = 0
      this.getData()
    },
    Onload () {
      this.getData()
      this.loading = false
    },
    init () {
      this.getStatus()
      this.page = 1
      this.list = []
      this.total = 0
      this.finished = false
      this.loading = false
      this.tabsData = tabsData
      this.tabActive = this.$route.query.tabName
      this.getData()

    },
    onClickRight () {
      this.showResult = false
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>