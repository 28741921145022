<template>
  <Card class="allSearchCard">
    <template>
      <template slot="title">
        <div class="card-info">
          <h3 class="name">{{cardInfo.accountName || cardInfo.qbsAccountName}}
            <!-- <Icon name="phone" /> -->
          </h3>
          <span class="tag">{{cardInfo.testDriveStatus}}</span>
        </div>
        <div class="description">
          <h4 class="accountMobile">
            <a :href="`tel:${cardInfo.qbsAccountPhone}`">
            <Icon
              name="phone"
            /></a>{{ type !== 'TDFollowUp' ? cardInfo.accountPhone : cardInfo.qbsAccountPhone}}
          </h4>
        </div>
      </template>
      <template>
        <div class="msg-text">
          <span>{{type === 'TDFollowUp' ? '最近跟进:' : '预约门店:'}}</span>
          <span>{{type === 'TDFollowUp' ? cardInfo.qbsFollowupTime : cardInfo.storeName}}</span>
        </div>
        <div
          class="msg-text"
          style="display:flex;justify-content: space-between;"
        >
          <div>
            <span>{{type === 'TDFollowUp' ? '跟进信息:' : '预约日期:'}}</span>
            <span>{{type === 'TDFollowUp' ? cardInfo.qbsFollowupMessage : (cardInfo.appointmentDate + '   ' + cardInfo.appointmentTime)}}</span>
          </div>
          <span
            class="pdc-detail"
            @click="testdriveDetail(cardInfo.testDriveId)"
            v-if="type === 'PDC'"
          >试驾详情<Icon
              name="arrow"
              color="#DA3738"
            ></Icon></span>
        </div>

      </template>
      <template v-if="type !== 'TDFollowUp'">
        <div class="msg-text">
          <span>CoPilot：</span>
          <span>{{cardInfo.coPilotName}}</span>
        </div>
      </template>
      <template slot="footer">
        <span class="msg">
          <Icon name="manager" />{{cardInfo.ownerUserName || cardInfo.ownerName}}
        </span>
        <div>
          <a :href="`tel:${cardInfo.qbsAccountPhone}`">
            <Icon
              name="phone-circle-o"
              size="30"
              color="#1899FF"
            />
          </a>
          <Button
            round
            type="info"
            size="small"
            style="margin-right:10px"
            @click="goAccountDetail( type === 'TDFollowUp' ? cardInfo.eosAccountId : cardInfo.accountId)"
          >客户详情</Button>
          <Button
            round
            v-if="type === 'followUp' || type === 'TDNoShow'"
            type="info"
            style="margin-right:10px"
            size="small"
            @click="testdriveDetail(cardInfo.testDriveId)"
          >试驾详情</Button>
          <Button
            round
            v-if="type === 'PDC'"
            type="info"
            size="small"
            @click="finish(cardInfo.testDriveId)"
          >完成任务</Button>
          <Button
            round
            v-if="type === 'TDFollowUp'"
            @click="followup(cardInfo.eosOppId)"
            type="info"
            size="small"
          >跟进</Button>
        </div>
      </template>
    </template>
  </Card>
</template>
<script>
import { Button, Icon, Toast } from 'vant'
import Card from '_c/card'
import { orgCode } from '@/api/chargequery'
import 'vant/lib/icon/style'
import 'vant/lib/row/style'
import 'vant/lib/col/style'
import '@/style/vant-patch.less'
import './index.less'
export default {
  name: 'CardItem',
  props: {
    cardInfo: {
      type: Object,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data () {
    return {
    }
  },
  components: {
    Card,
    Button,
    Icon
  },
  methods: {
    // 完成任务
    finish (id) {
      this.$emit('finishTestdrive', id)
    },
    // 跳转至试驾详情
    testdriveDetail (id) {
      this.$router.push({
        path: '/testDriveDetail',
        query: { id }
      })
    },
    // 跟进
    followup (id) {
      this.$router.push({
        path: '/businessOpportunitiesFollowUpFrom',
        query: { eosOppId: id }
      })
    },
    // 跳转到客户详情
    goAccountDetail (accountId) {
      this.$router.push({
        path: '/customerInfo',
        query: { 'id': accountId }
      })
    },
    init () {
      // console.log(111)
    },
    // 点击电话按钮
    handlephine (cardinfo) {
      const callphone = cardinfo.accountPhone
      this.orgCode(callphone)
    },
    // 拨打电话
    async orgCode (callphone) {
      const res = await orgCode({ orgCode: callphone }) //
      if (res.code === '000000') {
        Toast('Calling')
      } else {
        Toast(res.msg)
      }
    },
  },
  mounted () {
    this.init()
  }
}
</script>